@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer utilities {
  @import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300;400&display=swap");

  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75,300;0,85,400;0,90,400;0,95,400;1,80,300;1,90,500;1,95,500;1,95,700&display=swap");

  @import url("https://p.typekit.net/p.css?s=1&k=koy4wzd&ht=tk&f=4836.26063.26000.26032.26046&a=8218256&app=typekit&e=css");

  @font-face {
    font-family: "Blackjack";
    src: url("./fonts/BlackJack.ttf");
  }

  @font-face {
    font-family: "Londrina Solid", cursive;
  }

  @font-face {
    font-family: "ff-prater-serif-web";
    src: url("https://use.typekit.net/af/60d99f/0000000000000000773599ec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/60d99f/0000000000000000773599ec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/60d99f/0000000000000000773599ec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    /* font-weight: 400; */
  }

  @font-face {
    font-family: "acumin-pro";
    src: url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
        format("woff"),
      url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    /* font-weight: 600; */
  }

  @font-face {
    font-family: "acumin-pro-condensed";
    src: url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    /* font-weight: 400; */
  }

  @font-face {
    font-family: "acumin-pro-extra-condensed";
    src: url("https://use.typekit.net/af/ca6558/00000000000000003b9acb18/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/ca6558/00000000000000003b9acb18/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff"),
      url("https://use.typekit.net/af/ca6558/00000000000000003b9acb18/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    /* font-weight: 300; */
  }

  @font-face {
    font-family: "acumin-pro-semi-condensed";
    src: url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    /* font-weight: 400; */
  }

  .font-width-75 {
    font-stretch: 75%;
  }

  .font-width-85 {
    font-stretch: 85%;
  }

  .font-width-90 {
    font-stretch: 90%;
  }

  body {
    font-family: "Open Sans", sans-serif;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: transparent;
    border: 2px solid #942644;
    cursor: pointer;
    height: 1rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1rem;
  }

  .round label:after {
    font-size: 1.2rem;
    content: "✓";
    left: 0px;
    opacity: 0;
    position: absolute;
    top: -8px;
    transform: rotate(345deg);
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: transparent;
    border-color: #942644;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .bottom-half {
    position: absolute;
    bottom: 0;
    margin-bottom: -20px;
    right: 21%;
  }

  .dot {
    margin-left: 2px;
    height: 8px;
    width: 8px;
    background-color: rgba(252, 211, 77);
    border-radius: 50%;
    display: inline-block;
  }

  .disabled[type="radio"]:checked:hover,
  .disabled[type="radio"]:checked:focus {
    border-color: transparent;
    background-color: white;
  }

  .text-input-color {
    color: #2f323f;
  }

  .vegetarian:first-of-type{
    margin-left: 0px;
    }

  @media only screen and (min-width: 600px) {
    .intro{
      min-height: 950px !important;
    }
  }
}
